import {Helmet} from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {Button, Container, FormControl, Grid} from '@mui/material';
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {http, makeURL} from "../../utils";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";


interface TestQuestion {
    questionID: string
    question: string;
    options: string[];
    correct: number
}

interface TestInfo {
    testID: string;
    title: string;
    ready: boolean;
    questions: TestQuestion[];
}


function Test() {
    const {t} = useTranslation();
    let {testID} = useParams<{ testID?: string }>();

    // Now, you can use this testID somewhere inside your component
    console.log(testID);
    const [title, setTitle] = useState('');
    const [testInfo, setTestInfo] = useState<TestInfo>(
        {testID: "", title: "", ready: false, questions: []}
    );

    const [verifyState, setVerifyState] = useState(false);

    const [selectedAnswers, setSelectedAnswers] = useState(() => Array(testInfo.questions.length).fill(''));

    const navigate = useNavigate();

    const handleCheck = async (event) => {
        setVerifyState(!verifyState)
    }


    useEffect(() => {
        const fetchTestInfo = async () => {
            const response = await http.get(makeURL("/api/test/" + testID));
            return {
                testID: response.data.testID,
                title: response.data.title,
                ready: response.data.ready,
                questions: response.data.questions,
            }
        }

        const interval = setInterval(() => {
            fetchTestInfo().then(res => {
                if (res.ready) {
                    setTestInfo(res);
                    clearInterval(interval);
                }
            });
        }, 1000); // fetch test info every second
        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [testID]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        // const newLesson = {
        //     title: title,
        //     content: content
        // };
        //
        // try {
        //     const response = await http.post(makeURL('/api/lesson'), newLesson);
        //     console.log(response);
        //
        //     navigate(`/test/${response.data.id}`);
        //
        // } catch (error) {
        //     console.error(error);
        // }
    };

    if (testInfo.ready) {
        return (
            <>
                <Helmet>
                    <title>{t("test.title")}</title>
                </Helmet>
                <PageTitleWrapper>
                    <PageHeader header={t('test.header')} title={testInfo.title}/>
                </PageTitleWrapper>
                <Container maxWidth="lg">
                    <List>
                        {testInfo.questions.map((question: TestQuestion, index: number) => (
                            <ListItem key={index}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{question.question}</FormLabel>
                                    <RadioGroup
                                        aria-label={`question${index}`}
                                        defaultValue=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            // Logic to handle selected option
                                            console.log(`Selected option for question${index}: ${event.target.value}`);
                                        }}>
                                        {question.options.map((option, optionIndex) => (
                                            <FormControlLabel
                                                key={optionIndex}
                                                value={optionIndex}
                                                control={<Radio/>}
                                                label={option}
                                                // sx={optionIndex === 0 && {color: "red"}}
                                                sx={(verifyState && optionIndex === question.correct) && {
                                                    color: "green",
                                                    '.MuiFormControlLabel-label': {
                                                        fontWeight: 'bold'
                                                    }
                                                }}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl> </ListItem>
                        ))}
                    </List>
                    <Button variant={"contained"} onClick={handleCheck}>Check</Button>
                </Container>
            </>
        );
    } else {
        return (
            <>
                <Helmet>
                    <title>{t("test.title")}</title>
                </Helmet>
                <PageTitleWrapper>
                    <PageHeader header={t("test.please.wait")} title={testInfo.title}/>
                </PageTitleWrapper>
                <Container maxWidth="lg">
                </Container>
            </>
        );
    }
}

export default Test;
