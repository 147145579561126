import {Helmet} from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {Container, Grid} from '@mui/material';
import {useTranslation} from "react-i18next";
import AddButton from "../../components/AddButton";


function Dashboard() {
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("dashboard.title")}</title>
            </Helmet>
            <AddButton/>
        </>
    );
}

export default Dashboard;
