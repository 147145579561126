// src/components/LanguageSelector.tsx
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ukFlag from '../assets/flags/uk.png'; // Adjust path as needed
import bgFlag from '../assets/flags/bg.png'; // Adjust path as needed

const LanguageSelector: React.FC = () => {
    const {i18n} = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        setShowDropdown(false);
    };


    return (
        <div style={{position: 'fixed', top: 10, right: 10}}>
            <div onClick={() => setShowDropdown(!showDropdown)}
                 style={{cursor: 'pointer', padding: '5px'}}>
                {i18n.language === "en" ? (
                    <img src={ukFlag} width="32px" style={{marginRight: '5px'}}/>
                ) : (
                    <img src={bgFlag} width="32px" style={{marginRight: '5px'}}/>
                )
                }
            </div>
            {showDropdown && (
                <div
                    style={{position: 'absolute', top: '100%', right: 0, backgroundColor: 'white', border: '1px solid #ddd', borderRadius: '5px', padding: '5px'}}>
                    <div onClick={() => changeLanguage('en')} style={{cursor: 'pointer', padding: '5px'}}>
                        <img src={ukFlag} alt="English" width="32px" style={{marginRight: '5px'}}/>
                    </div>
                    <div onClick={() => changeLanguage('bg')} style={{cursor: 'pointer', padding: '5px'}}>
                        <img src={bgFlag} alt="Български" width="32px" style={{marginRight: '5px'}}/>
                    </div>
                </div>
            )}
        </div>
    );
};
export default LanguageSelector;
