import {useContext, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
    Avatar,
    Box,
    Button,
    Divider,
    MenuList,
    alpha,
    IconButton,
    MenuItem,
    ListItemText,
    Popover,
    Typography,
    styled,
    useTheme
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import Chart from 'react-apexcharts';
import type {ApexOptions} from 'apexcharts';
import Text from 'src/components/Text';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import {useAuth} from "../../../../contexts/AuthContext";


const UserBoxButton = styled(IconButton)(
    ({theme}) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
);

const UserAvatar = styled(Avatar)(
    ({theme}) => `
        height: 90%;
        width: 90%;
        border-radius: ${theme.general.borderRadiusLg};
`
);


const MenuUserBox = styled(Box)(
    ({theme}) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({theme}) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
    const {t}: { t: any } = useTranslation();
    const theme = useTheme();

    const navigate = useNavigate();


    const {userInfo, logout} = useAuth();

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            await logout();
            navigate('/');
        } catch (err) {
            console.error(err);
        }
    };


    return (
        <>
            <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
                <UserAvatar alt={"S"}/>
            </UserBoxButton>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuUserBox
                    sx={{
                        minWidth: 210
                    }}
                    display="flex"
                >
                    <Avatar variant="rounded" alt={userInfo.Name}/>
                    <UserBoxText>
                        <UserBoxLabel variant="body1">{t("Settings")}</UserBoxLabel>
                        <UserBoxDescription variant="body2">
                            {t("Settings")}
                        </UserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                <Divider
                    sx={{
                        mb: 0
                    }}
                />
                <Box m={1}>
                    <Button color="primary" fullWidth onClick={handleLogout}>
                        <LockOpenTwoToneIcon
                            sx={{
                                mr: 1
                            }}
                        />
                        {t('Sign out')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderUserbox;
