import {Box, Container, Link, Typography, styled, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";

const FooterWrapper = styled(Container)(
    ({theme}) => `
        margin-top: ${theme.spacing(2)};
        color: ${theme.colors.alpha.trueWhite[70]};
`
);

function SidebarFooter() {
    const {t, i18n} = useTranslation()
    const theme = useTheme();

    return (
        <FooterWrapper>
            <Box
                pb={4}
                display={{xs: 'block', md: 'flex'}}
                alignItems="center"
                textAlign={{xs: 'center', md: 'left'}}
                justifyContent="space-between"
            >
                <Box>
                    <Typography
                        sx={{
                            color: theme.colors.alpha.trueWhite[70]

                        }}
                        variant="subtitle1"
                    >
                        &copy; 2023 Mindloop
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        pt: {xs: 2, md: 0},
                    }}
                    variant="subtitle1"
                >
                    <Link
                        href="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("terms.link")}
                    </Link>
                </Typography>
            </Box>
        </FooterWrapper>
    );
}

export default SidebarFooter;
