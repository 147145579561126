import type {ReactNode} from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import {OverridableComponent} from "@mui/material/OverridableComponent";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";

export interface MenuItem {
    link?: string;
    icon?: OverridableComponent<any>;
    badge?: string;
    badgeTooltip?: string;

    items?: MenuItem[];
    name: string;
}

export interface MenuItems {
    items: MenuItem[];
    heading: string;
}

const menuItems: MenuItems[] = [
    {
        heading: 'Topics',
        items: [
            {
                name: 'New lesson',
                icon: AddCircleIcon,
                link: '/add',
            },
        ]
    },
    // {
    //     heading: 'Management',
    //     items: [
    //         {
    //             name: 'Users',
    //             icon: AssignmentIndTwoToneIcon,
    //             link: '/extended-sidebar/management/users',
    //             items: [
    //                 {
    //                     name: 'List',
    //                     link: 'management/users/list'
    //                 },
    //                 {
    //                     name: 'User Profile',
    //                     link: 'management/users/single'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Projects',
    //             icon: AccountTreeTwoToneIcon,
    //             link: '/extended-sidebar/management/projects/list'
    //         },
    //         {
    //             name: 'Commerce',
    //             icon: StorefrontTwoToneIcon,
    //             link: '/extended-sidebar/management/commerce',
    //             items: [
    //                 {
    //                     name: 'Shop',
    //                     link: 'management/commerce/shop'
    //                 },
    //                 {
    //                     name: 'List',
    //                     link: 'management/commerce/products/list'
    //                 },
    //                 {
    //                     name: 'Details',
    //                     link: 'management/commerce/products/single/1'
    //                 },
    //                 {
    //                     name: 'Create',
    //                     link: 'management/commerce/products/create'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Invoices',
    //             icon: ReceiptTwoToneIcon,
    //             link: '/extended-sidebar/management/invoices',
    //             items: [
    //                 {
    //                     name: 'List',
    //                     link: 'management/invoices/list'
    //                 },
    //                 {
    //                     name: 'Details',
    //                     link: 'management/invoices/single'
    //                 }
    //             ]
    //         }
    //     ]
    // },
];

export default menuItems;
