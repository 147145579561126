import {Box} from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';

function HeaderButtons() {
    return (
        <Box>
            <LanguageSwitcher/>
        </Box>
    );
}

export default HeaderButtons;
