import {Link as RouterLink, useLocation} from 'react-router-dom';
import {Box, Grid, Typography, Button, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';

const RootWrapper = styled(Box)(
    () => `
    flex: 1;
`
);

function PageHeader({header, title}) {
    const {t}: { t: any } = useTranslation();
    const location = useLocation();

    return (
        <RootWrapper>
            <Grid container justifyContent="space-between" alignItems="center">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography variant="h3" component="h3" gutterBottom>
                            {header}
                        </Typography>
                        <Typography variant="subtitle2">
                            {title}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </RootWrapper>
    );
}

export default PageHeader;
