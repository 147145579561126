// src/contexts/AuthContext.js
import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {makeURL, http} from "../utils";

interface UserInfo {
    Setup: boolean;
    Name: string;
    Email: string;
    Language: string;
}

// Define the shape of the context
interface AuthContextType {
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
    logout: () => void
}

// Create the context with an initial undefined value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({children}: AuthProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
        !!localStorage.getItem('jwtToken')
    );
    const [userInfo, setUserInfo] = useState<UserInfo>(
        {Email: "", Language: "", Name: "", Setup: false}
    );

    useEffect(() => {
        // Get User Info
        if (!localStorage.getItem('jwtToken') || userInfo.Setup) {
            return
        }

        // check if the page is /404
        if (window.location.pathname === '/404') {
            return
        }

        http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwtToken')

        http.get(makeURL("/api/user")).then((response) => {
            setUserInfo({
                Email: response.data.email,
                Language: response.data.language || "en",
                Name: response.data.name,
                Setup: response.data.setupCompleted
            })
        })


        // const token: string = localStorage.getItem('jwtToken') || "";
        // setIsAuthenticated(!!token);
        setUserInfo({Email: "", Language: "", Name: "", Setup: true})
    }, []);

    const logout = (): void => {
        window.location.href = "/logout"
    }

    return (
        <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, userInfo, setUserInfo, logout}}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook for consuming context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};