import {FC, useContext, useRef, useState, MouseEvent} from 'react';
import {
    Popover,
    styled,
    Button,
    MenuItem,
    Menu,
    Typography,
    Stack,
    Divider,
    Box,
    Tooltip
} from '@mui/material';
import {ThemeContext} from 'src/theme/ThemeProvider';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import Add from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import {useTranslation} from 'react-i18next';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import {NavLink, useNavigate} from 'react-router-dom';

const AddNewButton = styled(Box)(
    ({theme}) => `
          position: fixed;
          z-index: 9999;
          right: ${theme.spacing(4)};
          bottom: ${theme.spacing(4)};
          
          &::before {
              width: 30px;
              height: 30px;
              content: ' ';
              position: absolute;
              border-radius: 100px;
              left: 13px;
              top: 13px;
              background: ${theme.colors.primary.main};
              // animation: ripple 1s infinite;
              transition: ${theme.transitions.create(['all'])};
          }

          .MuiSvgIcon-root {
            // animation: pulse 1s infinite;
            transition: ${theme.transitions.create(['all'])};
          }
  `
);


const AddButton: FC = () => {
    const {t}: { t: any } = useTranslation();

    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate("/add")
    }

    const ref = useRef<any>(null);

    return (
        <>
            <AddNewButton>
                <Tooltip arrow title={t('lesson.add.header')}>
                    <Fab ref={ref} onClick={handleNavigate} color="primary" aria-label="add">
                        <Add/>
                    </Fab>
                </Tooltip>
            </AddNewButton>
        </>
    );
};

export default AddButton;
