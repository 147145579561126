import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationBG from './locales/bg.json';

// The translations
const resources = {
    en: {
        translation: translationEN,
    },
    bg: {
        translation: translationBG,
    },
};

i18n
    // Load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/src)
    // Learn more: https://github.com/i18next/i18next-http-backend
    // Detect user language
    // Learn more: https://github.com/i18next/i18next-browser-languagedetector
    // .use(LanguageDetector)
    // Pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // Initialize i18next
    // For all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: 'en', // Default language
        fallbackLng: 'en', // Use 'en' if detected lng is not available
        debug: true,
        interpolation: {
            escapeValue: false, // Not needed for React as it escapes by default
        },
    });

export default i18n;
