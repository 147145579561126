// src/App.tsx
import {useRoutes} from 'react-router-dom';
import router from 'src/router';

import "./i18n";
import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Index from './pages/Login';
import {useAuth} from "./contexts/AuthContext";
import ThemeProvider from './theme/ThemeProvider';
import {CssBaseline} from "@mui/material";

const App: React.FC = () => {
    const {isAuthenticated, userInfo} = useAuth();
    const content = useRoutes(router);

    if (!isAuthenticated) {
        return (
            <Index/>
        )
    }


    return (
        <ThemeProvider>
            <CssBaseline/>
            {content}
        </ThemeProvider>
    );
};

export default App;