import {useRef, useState} from 'react';

import {
    IconButton,
    Box,
    List,
    ListItem,
    Typography,
    ListItemText,
    alpha,
    Popover,
    Tooltip,
    styled,
    useTheme
} from '@mui/material';

import {useTranslation} from 'react-i18next';

import {GB} from 'country-flag-icons/react/3x2';
import {BG} from 'country-flag-icons/react/3x2';

const SectionHeading = styled(Typography)(
    ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const IconButtonWrapper = styled(IconButton)(
    ({theme}) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function LanguageSwitcher() {
    const {i18n, t} = useTranslation();

    const getLanguage = i18n.language;
    const theme = useTheme();

    const switchLanguage = ({lng}: { lng: any }) => {
        i18n.changeLanguage(lng);
    };
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip arrow title={t('language.switcher.header')}>
                <IconButtonWrapper
                    color="secondary"
                    ref={ref}
                    onClick={handleOpen}
                    sx={{
                        mx: 1,
                        background: alpha(theme.colors.error.main, 0.1),
                        transition: `${theme.transitions.create(['background'])}`,
                        color: theme.colors.error.main,

                        '&:hover': {
                            background: alpha(theme.colors.error.main, 0.2)
                        }
                    }}
                >
                    {getLanguage === 'en' && <GB title="English"/>}
                    {getLanguage === 'bg' && <BG title="Български"/>}
                </IconButtonWrapper>
            </Tooltip>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box
                    sx={{
                        maxWidth: 240
                    }}
                >
                    <List
                        sx={{
                            p: 2,
                            svg: {
                                width: 26,
                                mr: 1
                            }
                        }}
                        component="nav"
                    >
                        <ListItem
                            className={
                                getLanguage === 'en' || getLanguage === 'en-US' ? 'active' : ''
                            }
                            button
                            onClick={() => {
                                switchLanguage({lng: 'en'});
                                handleClose();
                            }}
                        >
                            <GB title="English"/>
                            <ListItemText
                                sx={{
                                    pl: 1
                                }}
                                primary="English"
                            />
                        </ListItem>
                        <ListItem
                            className={getLanguage === 'bg' ? 'active' : ''}
                            button
                            onClick={() => {
                                switchLanguage({lng: 'bg'});
                                handleClose();
                            }}
                        >
                            <BG title="Български"/>
                            <ListItemText
                                sx={{
                                    pl: 1
                                }}
                                primary="Български"
                            />
                        </ListItem>
                    </List>
                </Box>
            </Popover>
        </>
    );
}

export default LanguageSwitcher;
