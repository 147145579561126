import axios from "axios"
import {Simulate} from "react-dom/test-utils";

const makeURL = (parts: string): string => {
    const url: string = process.env.REACT_APP_BACKEND_URL || "";

    return url.replace(/\/$/, "") + parts;
}

const isAuthTokenValid = (): boolean => {
    const token = localStorage.getItem('jwtToken');
    return !!token;
};

const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
})

http.interceptors.request.use(
    (config) => {
        // call some function which returns the token
        const token = localStorage.getItem("jwtToken")
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        // you can also add more headers here
        return config;
    },
    (error) => Promise.reject(error)
);
http.interceptors.response.use(
    (response) => {
        // Any status code that lies within the range of 2xx causes this function to trigger.
        // So here you can handle your successful responses.
        return response;
    },
    (error) => {
        // Any status code that falls outside the range of 2xx causes this function to trigger.
        // Here you can handle your error responses.
        if (error.response) {
            const {status} = error.response;
            if (status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.reload()
                return;
            }
            if (status === 404) {
                window.location.href = "/status/404";
                return;
            }
            if (status === 500) {
                window.location.href = "/status/500";
                return;
            }
            if (status === 400) {
                window.location.href = "/status/500";
                return;
            }
        }
        return Promise.reject(error);
    }
);

export {makeURL, isAuthTokenValid, http}
