// src/components/index.tsx
import React, {useState, KeyboardEvent, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {TextField, Button, Box, Typography, InputAdornment, CircularProgress} from '@mui/material';
import {http, makeURL} from "../../utils";
import {useTranslation} from 'react-i18next';
import LanguageSelector from "../../components/LanguageSelector";


type ActionFunction = () => void;

const onPressEnter = (action: ActionFunction) => {
    return (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            action();
        }
    }
}

const Index: React.FC = () => {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [codeNonce, setCodeNonce] = useState('');
    const [code, setCode] = useState('');
    const [stage, setStage] = useState('email'); // 'email' or 'code'
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
            if (window.location.href.endsWith("logout")) {
                window.location.href = "/"
            }
        }
    )

    const handleSendEmail = async () => {
        try {
            setLoading(true);
            setError('');
            const response = await fetch(makeURL("/auth/login"), {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email}),
            });
            const data = await response.json();
            if (response.ok) {
                setStage('code');
                setCodeNonce(data.nonce)
                // Handle any additional actions, like storing the token if needed
            } else {
                setError(data.reason || t('login.error.general'));
            }
        } catch (err) {
            setError(t('login.error.oops'));
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSendCode = async () => {
        if (code == "") {
            return
        }
        try {
            setLoading(true);
            setError('');
            const response = await fetch(makeURL('/auth/verify?n=' + codeNonce), { // Update with your actual endpoint
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({code}),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('jwtToken', data.token);
                window.location.reload();
            } else if (response.status === 401) {
                setStage("email")
                setCode("");
                setError(t('login.error.invalidCode'));
            } else {
                setError(data.reason || t('login.error.general'));
            }

        } catch (err) {
            setError(t('login.error.oops'));
            console.error(err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <LanguageSelector/>
            <Box
                sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundImage: 'url("/background.png")',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'center center',
                    // backgroundSize: 'cover',
                    // backgroundAttachment: 'fixed',
                    height: '80vh',
                }}
                // sx={{
                //     marginLeft: 2,
                //     marginRight: 2,
                //     display: 'flex',
                //     flexDirection: 'column',
                //     alignItems: 'center',
                // }}
            >
                {/* Logo Image */}
                <img src="/logo.png" alt="Mindloop Logo" style={{maxWidth: '150px', marginBottom: '20px'}}/>

                <Typography variant="h3" component="h1" gutterBottom>
                    {/*MINDLOOP*/}
                </Typography>


                {stage === 'email' ? (
                    <TextField
                        fullWidth
                        label={t('login.input.email')}
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={onPressEnter(handleSendEmail)}
                        sx={{maxWidth: '400px'}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {loading ? (
                                        <CircularProgress size={24}/>
                                    ) : (
                                        <Button variant="contained"
                                                onClick={handleSendEmail}>{t('login.button.email')}</Button>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                ) : (
                    <React.Fragment>
                        <TextField
                            fullWidth
                            label={t("login.input.code")}
                            variant="outlined"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            onKeyUp={onPressEnter(handleSendCode)}
                            sx={{maxWidth: '400px'}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {loading ? (
                                            <CircularProgress size={24}/>
                                        ) : (
                                            <Button color="success" variant="contained"
                                                    onClick={handleSendCode}>{t("login.button.code")}</Button>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </React.Fragment>
                )}
                {error &&
                    <Typography color="error" sx={{marginTop: 2}}>
                        {error}
                    </Typography>}
                {((stage === "code") && (!error)) &&
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            marginTop: 2,
                            maxWidth: '400px',
                            mx: 'auto',
                            color: 'text.secondary'
                        }}
                    >
                        {t("login.code.text1")}
                        <br/><br/>
                        {t("login.code.text2")}
                        <br/><br/>
                        {t("login.code.text3")}
                    </Typography>
                }

            </Box>
        </div>
    );
};

export const Logout: React.FC = () => {
    useEffect(() => {
            const logoutHandler = async () => {
                // Call POST to /api/logout
                localStorage.removeItem("jwtToken")
                await http.post(makeURL('/api/logout'))
                window.location.href = "/"
            }

            logoutHandler();
        }
    )

    return (
        <>
        </>
    )
}

export default Index;
