import {Suspense, lazy} from 'react';
import {Navigate} from 'react-router-dom';
import {RouteObject} from 'react-router';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {Logout} from "./pages/Login";
import ExtendedSidebarLayout from "./layouts/ExtendedSidebarLayout";
import Dashboard from "./pages/Dashboard";
import Test from "./pages/Test";

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

// Pages

const AddLesson = Loader(lazy(() => import('src/pages/AddLesson')));

// Applications

const Transactions = Loader(
    lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
    lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
    lazy(() => import('src/content/applications/Users/settings'))
);


// Status

const Status404 = Loader(
    lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
    lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
    lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
    lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
    {
        path: '/logout',
        element: <Logout/>
    },
    {
        path: '',
        element: <ExtendedSidebarLayout/>,
        children: [
            {
                path: '/',
                element: <Dashboard/>
            },
            {
                path: 'overview',
                element: <Navigate to="/" replace/>
            },
            {
                path: '/add',
                element: <AddLesson/>
            },
            {
                path: '/test/:testID',
                element: <Test/>
            },
            {
                path: 'status',
                children: [
                    {
                        path: '404',
                        element: <Status404/>
                    },
                    {
                        path: '500',
                        element: <Status500/>
                    },
                    // {
                    //     path: 'maintenance',
                    //     element: <StatusMaintenance/>
                    // },
                    // {
                    //     path: 'coming-soon',
                    //     element: <StatusComingSoon/>
                    // }
                ]
            },
            {
                path: '*',
                element: <Status404/>
            }
        ]
    },
];

export default routes;
